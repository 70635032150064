<template>
  <b-overlay :show="isProcessing">
    <div class="custom-search d-flex justify-content-end">
      <b-form-group
        label-cols-md="2"
        class="w-100 col-md-4 px-0"
        :label="IS_MOBILE() ? '' : $t('Search')"
      >
        <b-form-input
          v-model="searchTerm"
          placeholder="Search"
          type="text"
          class="d-inline-block"
        />
      </b-form-group>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'percent_of_minutes'">
          <b-progress
            :max="100"
            height="1.5rem"
            variant="danger"
            class="progress-bar-success"
            style="border-radius: 0;"
          >
            <b-progress-bar
              v-b-tooltip.hover.top="`Idle ${props.row.idleDuration}  in ${props.row.duration}`"
              :value="props.row.percent_of_minutes"
              style="border-radius: 0;"
            >
              <span>{{ Math.round(props.row.percent_of_minutes,0) }} %</span>
            </b-progress-bar>
          </b-progress>
        </span>
      </template>
    </vue-good-table>
  </b-overlay>
</template>
<script>
import {
  BFormGroup, BFormInput, BProgress, BProgressBar, BOverlay, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BProgress,
    BProgressBar,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    expectedData: {
      type: Array,
      default: () => [],
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      searchTerm: '',
      columns: [
        {
          label: this.$t('table.name'),
          field: 'name',
        },
        {
          label: this.$t('reports.total-time'),
          field: 'duration',
          tdClass: 'col-120',
          thClass: 'col-120',
        },
        {
          label: this.$t('reports.idle-time'),
          field: 'idleDuration',
          tdClass: 'col-120',
          thClass: 'col-120',
        },
        {
          label: this.$t('reports.percent-of-minutes'),
          field: 'percent_of_minutes',
          thClass: 'col-360',
          tdClass: 'col-360',
        },
      ],
    }
  },
  computed: {
    rows() {
      return this.expectedData.map(data => ({
        name: data.name,
        duration: this.CONVERT_HM(data.duration),
        idleDuration: this.CONVERT_HM(data.duration * data.idlePercent),
        percent_of_minutes: data.idlePercent * 100,
      }))
    },
  },
}
</script>

<style>
.col-120 {
  min-width: 120px;
}
.col-360 {
  min-width: 360px;
}
</style>
