<template>
  <div>
    <filters
      v-model="form"
      :date-type="dateType"
      @timezone-changed="updateTimezone"
    />
    <div class="d-flex pb-2">
      <b-button
        v-for="dType of dateTypesList"
        :key="dType.value"
        :variant="dateType == dType.value ? 'primary' : 'flat'"
        class="mr-1"
        @click="dateType = dType.value; idealTimes()"
      >
        {{ $t(dType.text) }}
      </b-button>
    </div>
    <b-row v-if="hasData">
      <b-col
        v-for="ts of timeTitleSummary"
        :key="ts.title"
      >
        <b-card no-body>
          <b-card-body>
            <small>{{ $t(ts.title) }}</small>
            <h3>
              <span
                v-if="!ts.barPercentKey"
                class="float-left"
              >
                {{ workSummary[ts.key] ? CONVERT_HM(workSummary[ts.key]) : '00:00' }}
              </span>
              <span
                v-else
                class="float-left"
              >
                {{ CONVERT_HM(workSummary[ts.barPercentKey]) }} <span
                  class="d-none d-md-inline"
                  v-html="ts.timeSuffix"
                />
              </span>
              <template v-if="!ts.hidePercentage">
                <span
                  v-if="!ts.noCalculation"
                  class="float-right"
                >
                  {{
                    Math.round(CALC_PERCENTAGE({
                      total: workSummary.totalDuration,
                      value: workSummary[ts.key]
                    }),0)
                  }} %
                </span>

                <span
                  v-else
                  class="float-right"
                >
                  {{ number_format(workSummary[ts.key], 0) }} %
                </span>
              </template>
            </h3>
            <p class="mt-3">
              <b-progress
                :value="!ts.noCalculation ? CALC_PERCENTAGE({
                  total: workSummary.totalDuration,
                  value: workSummary[ts. key]
                }) : workSummary[ts.key]
                "
                max="100"
                :class="ts.progressClass"
                :variant="ts.progressVariant"
              />
            </p>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-tabs
          id="tour-idle-time"
          content-class="pt-1"
          pills
        >
          <b-tab
            v-for="at of applicationTypes"
            :key="at.value"
            :title="$t(at.title)"
          >
            <website-app
              :expected-data="topUses.filter(tu => tu.activityType === at.value)"
              :is-processing="isProcessing"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row v-else-if="!isProcessing">
      <b-col>
        <b-card>
          {{ $t('No data found') }}
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BProgress,
  BTabs, BTab,
  BButton,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import Filters from '@/views/common/components/Filter.vue'
import { getUserData } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { setComponentReady } from '@/views/tour/tour'
import WebsiteApp from './WebsiteApps.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BProgress,
    BTabs,
    BTab,
    WebsiteApp,
    Filters,
    BButton,
  },
  data() {
    return {
      timeTitleSummary: [
        {
          title: 'Total Working Time',
          key: 'recordsDuration',
          progressClass: 'progress-bar-success',
          progressVariant: 'success',
          hidePercentage: true,
        },
        {
          title: this.IS_MOBILE() ? 'Idle Minutes (Approx)' : 'Idle Minutes',
          key: 'idlePercent',
          progressClass: 'progress-bar-success',
          progressVariant: 'danger',
          noCalculation: true,
          barPercentKey: 'idleDuration',
          timeSuffix: '<sub class="small text-muted">APPROX</sub>',
        },
        {
          title: 'Unproductive Websites & Apps',
          key: 'unproductiveDuration',
          progressClass: 'progress-bar-success',
          progressVariant: 'danger',
        },
        {
          title: 'Manually Added Time',
          key: 'manualDuration',
          progressClass: 'progress-bar-success',
          progressVariant: 'success',
        },
      ],
      workSummary: {
        totalDuration: 0,
        unproductiveDuration: 0,
        idlePercent: 0,
        manualDuration: 0,
        recordsDuration: 0,
      },
      topUses: [],
      form: {
        date: this.TODAY({ localTime: false }),
        start_date: this.TODAY({ localTime: false }),
        end_date: this.TODAY({ localTime: false }),
        userId: getUserData().uuid,
        time_zone: localStorage.timezone,
      },
      applicationTypes: [
        { title: 'Application', value: 'APPLICATION' },
        { title: 'Website', value: 'WEBSITE' },
      ],
      isProcessing: true,
      hasData: false,
      dateType: 'single',
      dateTypesList: [
        { text: 'Single', value: 'single' },
        { text: 'Date Range', value: 'range' },
      ],
    }
  },
  computed: {
    ...mapGetters('project', ['GET_SELECTED_USER']),
    getUserId() {
      return this.form.userId
    },
    dateRange() {
      if (this.dateType === 'range') {
        return {
          start: this.FORMAT_DATE(this.form.start_date, 'YYYY-MM-DD 00:00:00'),
          end: this.ADD_DAYS(this.form.end_date, 1, 'YYYY-MM-DD 00:00:00').start,
        }
      }
      return {
        start: this.FORMAT_DATE(this.form.date, 'YYYY-MM-DD 00:00:00'),
        end: this.ADD_DAYS(this.form.date, 1, 'YYYY-MM-DD 00:00:00').start,
      }
    },
  },
  watch: {
    form: {
      handler() {
        this.idealTimes()
      },
      deep: true,
    },
    GET_SELECTED_USER: {
      handler(val) {
        if (val && val !== this.form.userid) this.form.userId = val
      },
      immediate: true,
    },
    getUserId(val) {
      if (val) this.setSelectedUser(val)
    },
  },
  mounted() {
    this.idealTimes()
  },
  methods: {
    ...mapActions('project', ['setSelectedUser']),
    idealTimes() {
      if (this.dateType === 'range') {
        this.setStartDate()
      }
      this.isProcessing = true
      useApollo.activities.getIdleTime({
        uuid: this.form.userId,
        keyword: this.$store.state.project.selectedProject,
        startTime: this.dateRange.start,
        endTime: this.dateRange.end,
      }).then(response => {
        const {
          idlePercent,
          recordsDuration,
          unproductiveDuration,
          manualDuration,
          topUses,
        } = response.data.user
        this.workSummary = {
          idlePercent: idlePercent * 100,
          unproductiveDuration,
          recordsDuration,
          manualDuration,
          totalDuration: recordsDuration,
          idleDuration: recordsDuration * idlePercent,
        }
        this.topUses = topUses.data
        this.hasData = true
        setComponentReady()
      }).catch(error => {
        console.error(error)
        this.hasData = false
      }).finally(() => { this.isProcessing = false })
    },
    updateTimezone() {
      if (this.isAfterTODAY(this.form.date)) this.form.date = this.TODAY({ localTime: false })
      if (this.isAfterTODAY(this.form.start_date)) this.form.start_date = this.TODAY({ localTime: false })
      if (this.isAfterTODAY(this.form.end_date)) this.form.end_date = this.TODAY({ localTime: false })
    },
    setStartDate() {
      this.form.start_date = this.FORMAT_DATE(moment(this.form.start_date).startOf('month'), 'YYYY-MM-DD')
    },
  },
}
</script>
